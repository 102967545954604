import React, { useEffect, useState } from "react";
import { addModalFields, editFields, TableHeaders } from "../constants/Data";
import { useParams } from "react-router-dom";
import { useCreateResourceMutation } from "../features/api/apiSlice";
import { BsFillPersonFill } from "react-icons/bs";
import { FaPhone } from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";
import { HoverEffect } from "../components/HoverEffect";
import { ItemNameIcon, LoadingIcon } from "../constants/Icons";
import PageTitle from "../components/PageTitle";
import DetailTable from "../components/DetailTable";
import DateButtons from "../components/DateButtons";

const CustomerDetail = () => {
  const { id } = useParams();

  const getCurrentDate = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = (now.getMonth() + 1).toString().padStart(2, "0");
    const day = now.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  };
  const startDate = () => {
    const now = new Date();
    now.setMonth(now.getMonth() - 1);
    const year = now.getFullYear();
    const month = (now.getMonth() + 1).toString().padStart(2, "0");
    const day = now.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  };
  const [searchQueries, setSearchQueries] = useState({});
  const [sortQuery, setSortQuery] = useState("");
  const [page, setPage] = useState(1);
  const itemsPerPage = 10;

  const [data, setData] = useState();

  const [formData, setFormData] = useState({
    startDate: startDate(-1),
    endDate: getCurrentDate(),
  });

  const handleDateChange = (field, value) => {
    setFormData({ ...formData, [field]: value });
  };
  const [createResource, { isError, isLoading, error, isFetching }] =
    useCreateResourceMutation();
  const handleSubmit = async (e) => {
    if (e) {
      e.preventDefault();
    }
    try {
      const response = await createResource({
        url: `/customers/${id}`,
        formData: formData,
      }).unwrap();
      setData(response);
    } catch (err) {
      console.log("err :", err?.data?.message);
    }
  };
  useEffect(() => {
    handleSubmit();
  }, []);
  if (isLoading)
    return (
      <div className="flex justify-center items-center h-[90vh]">
        <LoadingIcon />
      </div>
    );
  if (error) return <p className="p-20">{error?.data?.message}</p>;

  const customerInfo = data && data.data?.customer;
  const allPayDebts = data && data.data?.allPayDebts;

  const totalDebtsData = data && data.data?.totalDebts;
  const totalItemsSold = data && data.data?.totalItemsSold;
  const totalPayCash = data && data.data?.totalPayCash;

  if (!totalDebtsData) return <p>No totalDebts data found</p>;
  if (!customerInfo) return <p>No customer data found</p>;

  const { name, phoneNumber, address, purchases, debts } = customerInfo;

  const { totalDebtAmount, totalDebts } = totalDebtsData;

  const customer = [
    { id: 1, icon: <BsFillPersonFill />, data: name },
    { id: 2, icon: <FaPhone />, data: phoneNumber },
    { id: 3, icon: <FaLocationDot />, data: address },
  ];
  const proItems = [
    {
      id: 1,
      title: "کۆی گشتی پارەدان بە کاش",
      icon: <ItemNameIcon />,
      description: totalPayCash,
    },
    {
      id: 2,
      title: "کۆی گشتی ماددە فرۆشراوەکان",
      icon: <ItemNameIcon />,
      description: totalItemsSold,
    },
    {
      id: 3,
      title: "کۆی گشتی قەرزەکان",
      icon: <ItemNameIcon />,
      description: totalDebts,
    },
    {
      id: 4,
      title: "کۆی گشتی قەرزی ماوە",
      icon: <ItemNameIcon />,
      description: totalDebtAmount,
    },
  ];

  console.log("purchases :" , purchases)
  const tableConfigs = [
    {
      searchName: "فرۆشەکان",
      tableTitle: "فرۆشەکان",
      data: purchases,
      head: TableHeaders[0].purchaseDetailHeader,
      isFetching: isFetching,
      editFields: editFields[0].purchaseFields,
      printButton: true,
    },
    {
      searchName: "قەرزەکان",
      tableTitle: "قەرزەکان",
      data: debts,
      head: TableHeaders[0].debtDetailHeader,
      query: "debts",
      isFetching: isFetching,
      _returnButton: true,
      _textfield: addModalFields[0].payDebtFields,
      _returnAllButton: true,
    },
    {
      searchName: "گەڕاندنەوەی قەرز",
      tableTitle: "گەڕاندنەوەی قەرز",
      data: allPayDebts,
      head: TableHeaders[0].customerPayDebtDetailHeader,
      query: "payDebts",
      isFetching: isFetching,
      editFields: editFields[0].payDebtFields,
    },
  ];

  const handleSearchQueryChange = (tableIndex, value) => {
    setSearchQueries((prev) => ({
      ...prev,
      [tableIndex]: value,
    }));
  };
  const printData = {
    customer: name,
    phone: phoneNumber,
    location: address,
    customer_id: id,
    purchases: purchases,
    debts: debts,
    allPayDebts: allPayDebts,
    startDate: formData?.startDate,
    endDate: formData?.endDate,
  };
  const allPrintData = {
    customer: name,
    phone: phoneNumber,
    location: address,
    customer_id: id,
    remainDebt: totalDebtAmount,
  };

  return (
    <div className="w-full">
      <PageTitle title={"هەژمار"} />
      <DateButtons
        onSubmit={handleSubmit}
        onChange={handleDateChange}
        formData={formData}
        _printButton={true}
        printData={printData}
      />
      <div className="flex flex-col lg:flex-row justify-between">
        <div className="flex p-5">
          {customer.map((item) => (
            <div
              key={item.id}
              className="flex items-center px-2 text-xl lg:px-10 lg:text-2xl"
            >
              <div className="ml-2  text-primary">{item.icon}</div>
              <h1 className="">{item.data}</h1>
            </div>
          ))}
        </div>
      </div>
      <div className="">
        <HoverEffect items={proItems} />
      </div>
      <div className=" mx-2 lg:mx-12 2xl:mx-16 pb-20 ">
        {data &&
          tableConfigs.map((config, index) => (
            <DetailTable
              key={index}
              searchName={config.searchName}
              tableTitle={config.tableTitle}
              data={config.data}
              isFetching={config.isFetching}
              searchQuery={searchQueries[index] || ""}
              setSearchQuery={(value) => handleSearchQueryChange(index, value)}
              sortQuery={sortQuery}
              setSortQuery={setSortQuery}
              page={page}
              setPage={setPage}
              query={config.query}
              head={config.head}
              itemsPerPage={itemsPerPage}
              _editFields={config.editFields}
              _detailPath={config._detailPath}
              printButton={config.printButton}
              _returnButton={config._returnButton}
              _textfield={config._textfield}
              _returnAllButton={config._returnAllButton}
              queryId={id}
              payAllDebtQuery={`payDebts/distribute/${id}`}
              allPrintData={allPrintData}
              totalDebtAmount={totalDebtAmount}
            />
          ))}
      </div>
    </div>
  );
};

export default CustomerDetail;
