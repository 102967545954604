import React, { useMemo, useState } from "react";
import { useDeleteResourceMutation } from "../features/api/apiSlice";
import { Flowbite, Pagination } from "flowbite-react";

import ConfirmModal from "./ConfirmModal";
import EditModal from "./EditModal";
import { MdArrowBack, MdArrowForward, MdSearch } from "react-icons/md";
import { FaSearch } from "react-icons/fa";
import DropDown from "./DropDown";
import TextField from "./TextField";
import toast from "react-hot-toast";
import PrimaryButton from "./PrimaryButton";
import AddModal from "./AddModal";
import { IoMdArrowDropdown, IoMdArrowDropup } from "react-icons/io";
import { useTranslation } from "react-i18next";
import { LoadingIcon, NameIcon } from "../constants/Icons";
import { Link } from "react-router-dom";

const DetailTable = ({
  data,
  sortData,
  searchName,
  query,
  searchQuery,
  setSearchQuery,
  sortQuery,
  setSortQuery,
  page,
  setPage,
  head,
  _editFields,
  itemsPerPage, // Default items per page
  tableTitle,
  totalDaxilaPrice,
  _addButton,
  _textfield,
  actionButtonText,
  errorNames,
  _returnButton,
  isFetching,
  _detailPath,
  printButton,
  _returnAllButton,
  queryId,
  allPrintData,
  totalDebtAmount,
  payAllDebtQuery,
}) => {
  const { t } = useTranslation();
  const userDataFromLocalStorage = localStorage.getItem("user");

  let role = "";
  let branch = "";

  if (userDataFromLocalStorage) {
    try {
      const userData = JSON.parse(userDataFromLocalStorage);
      role = userData.role; // Correctly access the role
      branch = userData.branch; // Correctly access the role
    } catch (error) {
      console.error("Error parsing user data from local storage:", error);
    }
  }
  const [confirmModal, setConfirmModal] = useState(false);
  const [confirm, setConfirm] = useState("");
  const [editModal, setEditModal] = useState(false);
  const [title, setTitle] = useState(false);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [allPayDebtModal, setAllPayDebtModal] = useState(false);
  const [allSelfDebtModal, setAllSelfDebtModal] = useState(false);
  const [payDebtModal, setPayDebtModal] = useState(false);
  const [errMsg, setErrMsg] = useState(null);
  const [_id, setId] = useState("");
  const [printModal, setPrintModal] = useState(false);
  const [printData, setPrintData] = useState("");

  const [deleteResource, { isLoading: isDeleting }] =
    useDeleteResourceMutation();

  const handleSearchQueryChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handleConfirmDelete = async (e) => {
    e.preventDefault();
    try {
      const deletedData = await deleteResource(`/${query}/${_id}`).unwrap();
      toast.success("بە سەرکەوتوی سڕایەوە", {
        className: "py-4 text-xl",
        style: { direction: "rtl" },
      });
      setConfirmModal(false);
    } catch (error) {
      if (error.data?.error?.message) {
        setErrMsg(t(error.data?.error?.message));
      } else if (error?.data?.message) {
        setErrMsg(t(error?.data?.message));
      }
    }
  };
  // Sorting Logic
  const sortedData = useMemo(() => {
    if (!sortQuery) return data;

    // Determine the sort order (ascending or descending)
    const isAscending = sortQuery.startsWith("+");
    const field = sortQuery.slice(1); // Remove the + or - from the sortQuery

    let sortedArray = [...data];

    if (field === "createdAt") {
      sortedArray.sort((a, b) => {
        const dateA = new Date(a.createdAt);
        const dateB = new Date(b.createdAt);
        return isAscending ? dateA - dateB : dateB - dateA;
      });
    } else if (field === "sell_price") {
      sortedArray.sort((a, b) => {
        const priceA = a.item?.sell_price || 0;
        const priceB = b.item?.sell_price || 0;
        return isAscending ? priceA - priceB : priceB - priceA;
      });
    } else if (field === "purchase_price") {
      sortedArray.sort((a, b) => {
        const priceA = a.item?.purchase_price || 0;
        const priceB = b.item?.purchase_price || 0;
        return isAscending ? priceA - priceB : priceB - priceA;
      });
    } else if (field === "cash") {
      sortedArray.sort((a, b) => {
        const cashA = a.cash === true ? 1 : 0;
        const cashB = b.cash === true ? 1 : 0;
        return isAscending ? cashA - cashB : cashB - cashA;
      });
    }

    return sortedArray;
  }, [data, sortQuery]);

  const filteredData = useMemo(() => {
    if (!searchQuery) return sortedData;
    return sortedData.filter((item) => {
      return head.some((column) => {
        const value = item.item
          ? item.item?.[column.field]?.toString().toLowerCase()
          : item[column.field]?.toString().toLowerCase();
        return value && value.includes(searchQuery.toLowerCase());
      });
    });
  }, [sortedData, searchQuery, head]);

  // Pagination Logic
  const startIndex = (page - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const paginatedData = filteredData.slice(startIndex, endIndex);

  // Calculate total pages
  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  // Handle sort change
  const handleSortQueryChange = (value) => {
    setSortQuery(value);
  };

  const rows = paginatedData?.map((dataItem, rowIndex) => {
    const includedFields = head.map((item) => {
      if (item.field === "createdAt") {
        const date = new Date(dataItem[item.field]);
        const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1)
          .toString()
          .padStart(2, "0")}-${date
          .getDate()
          .toString()
          .padStart(2, "0")} ${date.toLocaleString("en-US", {
          hour: "numeric",
          minute: "numeric",
          hour12: true,
        })}`;
        return <div dir="ltr">{formattedDate}</div>;
      }
      if (item.field === "itemCreatedAt") {
        const date = new Date(dataItem.item?.createdAt);
        const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1)
          .toString()
          .padStart(2, "0")}-${date
          .getDate()
          .toString()
          .padStart(2, "0")} ${date.toLocaleString("en-US", {
          hour: "numeric",
          minute: "numeric",
          hour12: true,
        })}`;
        return <div dir="ltr">{formattedDate}</div>;
      }
      if (item.field === "itemQuantity") {
        return dataItem.items
          ? dataItem.items.map((item, index) => (
              <div key={index} class="">
                {item.quantity}
              </div>
            ))
          : "سڕاوەتەوە";
      }
      if (item.field === "debtInfo") {
        return (
          <Link
            to={`/DaxilaBranch/${dataItem._id}`}
            className="text-primary/80 hover:underline hover:text-primary duration-300 ease-in-out transition-all"
          >
            بینین
          </Link>
        );
      }
      if (item.field === "info") {
        return (
          <Link
            to={`/${_detailPath}/${dataItem._id}`}
            className="text-primary/80 hover:underline hover:text-primary duration-300 ease-in-out transition-all"
          >
            بینین
          </Link>
        );
      }
      if (item.field === "detailName") {
        return (
          <Link
            to={`/${_detailPath}/${dataItem._id}`}
            className="text-primary/80 hover:underline hover:text-primary duration-300 ease-in-out transition-all"
          >
            {dataItem.name}
          </Link>
        );
      }
      if (item.path === "itemName") {
        return dataItem.item ? dataItem.item?.name : "";
      }
      if (item.path === "itemCode") {
        return dataItem.item ? dataItem.item?.code : "";
      }
      if (item.path === "itemFee") {
        return dataItem.item ? dataItem.item?.fee : "";
      }
      if (item.path === "itemPurchase_price") {
        return dataItem.item ? dataItem.item?.purchase_price : "";
      }
      if (item.path === "itemPsell_price") {
        return dataItem.item ? dataItem.item?.sell_price : "";
      }
      if (item.path === "itemCompany") {
        return dataItem.item ? dataItem.item?.company?.name : "";
      }
      if (item.path === "itemAddedBy") {
        return dataItem.item ? dataItem.item?.addedBy?.name : "";
      }
      if (item.field === "cash") {
        return <p>{dataItem.cash === true ? "بەڵێ" : "نەخێر"}</p>;
      }
      if (item.field === "addedBy") {
        return dataItem.addedBy ? dataItem.addedBy.username : "سڕاوەتەوە";
      }
      if (item.field === "company") {
        return dataItem.company ? dataItem.company.name : "";
      }
      if (item.field === "branch") {
        return dataItem.branch
          ? dataItem.branch.name
          : dataItem.branch === null
          ? "سڕاوەتەوە"
          : "سیستەم";
      }
      if (item.field === "customer") {
        return dataItem.customer ? dataItem.customer.name : "";
      }
      if (item.field === "items") {
        return dataItem.items
          ? dataItem.items.map((item, index) => (
              <div key={index} class="">
                {item?.item !== null ? item.item?.name : "سڕاوەتەوە"}
              </div>
            ))
          : "سڕاوەتەوە";
      }
      if (item.field === "price") {
        return (
          <p
            className={`${
              dataItem.Added === false ? "text-red-600" : "text-green-600"
            }`}
          >
            {dataItem.price}
            {dataItem.Added === false ? (
              <IoMdArrowDropdown className="inline-block ml-1" />
            ) : (
              <IoMdArrowDropup className="inline-block ml-1" />
            )}
          </p>
        );
      }

      if (item.field === "actions") {
        const handleDelete = async (id) => {
          setConfirmModal(true);
          setId(id);
          setTitle(" ئایا دڵنیای لە سڕینەوە؟");
          setConfirm("delete");
        };
        const handleEdit = async (id) => {
          setEditModal(true);
          setId(id);
        };
        const handlePayDebt = (id, data) => {
          setPayDebtModal(true);
          setId(id);
          setPrintData(data);
        };
        const handlePrint = (data) => {
          setPrintData(data);
          setTitle("ئایا دڵنیای لە دوبارە پرینت کردن؟");
          setPrintModal(true);
        };

        return (
          <div className="flex justify-center gap-3 items-center">
            {tableTitle === "فرۆشەکان" ? (
              ""
            ) : (
              <p
                onClick={() => handleDelete(data[rowIndex]._id)}
                className="cursor-pointer text-red-600"
              >
                سڕینەوە
              </p>
            )}

            <p
              className="text-blue-600 cursor-pointer"
              onClick={() => handleEdit(data[rowIndex]._id)}
            >
              نوێکردنەوە
            </p>
            {_returnButton && (
              <button
                className="text-black cursor-buttonointer"
                onClick={() =>
                  handlePayDebt(data[rowIndex]._id, data[rowIndex])
                }
              >
                گەڕاندنەوە
              </button>
            )}
            {printButton && (
              <button onClick={() => handlePrint(data[rowIndex])}>
                پرینت کردن
              </button>
            )}
          </div>
        );
      }
      return dataItem[item.field];
    });
    return includedFields;
  });

  // Pagination Theme
  const customTheme = {
    base: "",
    layout: {
      table: {
        base: "text-sm text-gray-700 dark:text-gray-400",
        span: "font-semibold text-gray-900 dark:text-white",
      },
    },
    pages: {
      base: "xs:mt-0 mt-2 inline-flex items-center -space-x-px",
      showIcon: "inline-flex",
      previous: {
        base: "ml-0  border border-gray-300 bg-white px-3 py-2 leading-tight text-gray-500 enabled:hover:bg-gray-100 enabled:hover:text-gray-700 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-400 enabled:dark:hover:bg-gray-700 enabled:dark:hover:text-white",
        icon: "h-5 w-5",
      },
      next: {
        base: " border border-gray-300 bg-white px-3 py-2 leading-tight text-gray-500 enabled:hover:bg-gray-100 enabled:hover:text-gray-700 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-400 enabled:dark:hover:bg-gray-700 enabled:dark:hover:text-white",
        icon: "h-5 w-5",
      },
      selector: {
        base: "w-12 border border-gray-300 bg-white py-2 leading-tight text-gray-500 enabled:hover:bg-gray-100 enabled:hover:text-gray-700 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-400 enabled:dark:hover:bg-gray-700 enabled:dark:hover:text-white",
        active:
          "bg-cyan-50 text-cyan-600 hover:bg-cyan-100 hover:text-cyan-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white",
        disabled: "cursor-not-allowed opacity-50",
      },
    },
  };

  const handleAdd = async (id) => {
    setOpenAddModal(true);
    setId(id);
  };

  const handleAllPayDebt = async () => {
    if (payAllDebtQuery === "selfPayDebts/distribute") {
      setAllSelfDebtModal(true);
    } else {
      setAllPayDebtModal(true);
    }
    setId(queryId);
    setPrintData(allPrintData);
  };
  const payDebtFields = [
    {
      id: 1,
      label: "پارە",
      name: "payAmount",
      type: "number",
      placeholder: "ڕێژەی گەڕاندنەوە",
      error: "تکەیە ناوێک داخل بکە",
      icon: <NameIcon fill={"#A0AEC0"} />,
    },
    // {
    //   id: 2,
    //   label: "داشکاندن",
    //   name: "discount",
    //   type: "number",
    //   placeholder: "ڕێژەی گەڕاندنەوە",
    //   error: "تکەیە ناوێک داخل بکە",
    //   icon: <NameIcon fill={"#A0AEC0"} />,
    // },
    {
      id: 43,
      label: "تێبینی",
      name: "description",
      type: "text",
      placeholder: "تێبینیەکت هەیە ؟",
      error: "تکەیە ناوێک داخل بکە",
      icon: <NameIcon fill={"#A0AEC0"} />,
    },
  ];
  return (
    <div>
      <div className="overflow-x-auto rounded-lg">
        <div className="grid md:grid-cols-2 items-center my-10 ">
          <div>
            <h1 className="text-2xl tracking-wide font-bold">{tableTitle}</h1>
            <div className="mt-10">
              {_addButton === true && (
                <PrimaryButton
                  _text={"بەکارهێنان"}
                  onClick={handleAdd}
                  _additionalClasses="mt-4 lg:w-96 w-full"
                />
              )}
            </div>

            {totalDaxilaPrice ? (
              <h1 className="text-2xl mt-10">
                کۆی گشتی پارە لە دەخیلەی لق : {totalDaxilaPrice}
              </h1>
            ) : (
              ""
            )}
            {_returnAllButton === true && (
              <PrimaryButton
                _text={"گەڕاندنەوەی گشتی"}
                onClick={handleAllPayDebt}
                _additionalClasses="mt-4 lg:w-96 w-full"
              />
            )}
          </div>
        </div>
        <div className=" flex flex-col md:flex-row justify-between gap-10 items-center my-10 ">
          <div className="w-full">
            <TextField
              _id="search"
              _type="text"
              _placeholder={`گەڕان بۆ ${searchName}...`}
              _value={searchQuery}
              onChange={handleSearchQueryChange}
              _additionalClasses="w-full"
              _icon={FaSearch}
            />
          </div>
          <div className="w-full ml-2">
            {sortData && (
              <DropDown
                sortData={sortData}
                _value={sortQuery}
                onChange={handleSortQueryChange}
              />
            )}
          </div>
        </div>

        <table className="w-full text-center">
          <thead className={`bg-primary sticky top-0`}>
            <tr>
              {head.map((item) => (
                <th
                  scope="col"
                  className="px-2 py-3 text-xl text-white"
                  key={item.id}
                >
                  {item.name}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {isFetching ? (
              <tr>
                <td colSpan={head.length}>
                  <div
                    role="status"
                    className="flex justify-center items-center my-10"
                  >
                    <LoadingIcon />
                  </div>
                </td>
              </tr>
            ) : rows.length > 0 ? (
              rows.map((_data, index) => (
                <tr key={index} className="hover:bg-primary/20">
                  {_data.map((value, colIndex) => (
                    <td
                      key={colIndex}
                      className="p-4 border-b border-blue-gray-50 text-xl "
                    >
                      {value}
                    </td>
                  ))}
                </tr>
              ))
            ) : (
              <tr>
                <td
                  colSpan={head.length}
                  className=" text-primary font-bold py-5"
                >
                  <span className="flex items-center justify-center text-xl">
                    <MdSearch size={24} className="mr-2" /> هیچ داتایەک نیە....
                  </span>
                </td>
              </tr>
            )}
          </tbody>
          <tfoot className="bg-gray-200" dir="rtl">
            <tr>
              <td colSpan={head.length} className="py-4">
                <Flowbite theme={{ theme: customTheme }}>
                  <Pagination
                    layout="pagination"
                    previousLabel={<MdArrowForward size={20} />}
                    nextLabel={<MdArrowBack size={20} />}
                    currentPage={page}
                    totalPages={totalPages}
                    onPageChange={handlePageChange}
                    className="flex justify-center"
                  />
                </Flowbite>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
      {confirmModal && (
        <ConfirmModal
          isOpen={confirmModal}
          onCancel={() => setConfirmModal(false)}
          _title={title}
          confirm={confirm}
          onConfirm={handleConfirmDelete}
          submitButton={true}
          errMsg={errMsg}
        />
      )}
      {editModal && (
        <EditModal
          isOpen={editModal}
          onCancel={() => setEditModal(false)}
          id={_id}
          initialData={data}
          _query={query}
          _editFields={_editFields}
        />
      )}
      {openAddModal && (
        <AddModal
          _textfield={_textfield}
          _title={`بەکارهێنان`}
          onCancel={() => setOpenAddModal(false)}
          _query={query}
          actionButtonText={actionButtonText}
          errorNames={errorNames}
        />
      )}
      {printModal && (
        <ConfirmModal
          onCancel={() => setPrintModal(false)}
          _title={title}
          printButton={true}
          data={printData}
          totalDebtAmount={totalDebtAmount}
        />
      )}
      {payDebtModal && (
        <AddModal
          _textfield={_textfield}
          _title={`زیادکردنی `}
          onCancel={() => setPayDebtModal(false)}
          _query={`payDebts`}
          actionButtonText={actionButtonText}
          id={_id}
          _itemName={"debt"}
          _printButton={true}
          data={printData}
        />
      )}
      {allPayDebtModal && (
        <AddModal
          _textfield={payDebtFields}
          _title={`گەڕاندنەوەی گشتی `}
          onCancel={() => setAllPayDebtModal(false)}
          _query={payAllDebtQuery}
          actionButtonText={actionButtonText}
          _printButton={true}
          data={printData}
          id={_id}
          totalDebtAmount={totalDebtAmount}
        />
      )}
      {allSelfDebtModal && (
        <AddModal
          _textfield={payDebtFields}
          _title={`گەڕاندنەوەی گشتی `}
          onCancel={() => setAllSelfDebtModal(false)}
          _query={payAllDebtQuery}
          actionButtonText={actionButtonText}
          _printButton={true}
          data={printData}
          id={_id}
          _itemName={"companyId"}
        />
      )}
    </div>
  );
};

export default DetailTable;
